import React from "react"

import t from '../../texts/counterpart';

import * as styles from './Footer.module.css';

const Footer = () => {
  return (
    <div id="kontakt" className={styles.wrapper}>
      <footer className={styles.container}>
        <h2>{t('contactHeading')}</h2>
        <ul className={styles.list}>
          <li>
            <address>
              Refsnes Laks AS
              <br />
              Stokksundveien 1376
              <br />
              7177 Revsnes
              <br />
              Org nr: 980649598
            </address>
            <a href="https://goo.gl/maps/9nw3FWLeUJ4QQ6HS9" target="_blank" rel="noopener noreferrer">{t('footerMapLink')}</a>
          </li>

          <li>
            <address>
              Jon Refsnes
              <br />
              {t('contactCEO')} 
              <br />
              jon@refsneslaks.no
              <br />
              920 30 887 
            </address>
          </li>

          <li>
            <address>
              Ivar Refsnes
              <br />
              {t('contactChairman')} 
              <br />
              ivar@refsneslaks.no
              <br />
              970 89 660
            </address>
          </li>
        </ul>
      </footer>
    </div>
  )
}

export default Footer

const texts = {
  menuOpen: "Meny",
  menuClose: "Lukk",

  contactHeading: 'Kontakt oss',
  contactCEO: 'Administrerende direktør',
  contactChairman: 'Styreleder',

  navAbout: 'Om oss',
  navHistory: 'Vår historie',
  navFreshOcean: 'Friskt hav',
  navSeeSalmon: 'SeeSalmon',
  navContactUs: 'Kontakt oss',

  footerMapLink: 'Vis på Google Maps'
}

export default texts;
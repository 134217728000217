const texts = {
  menuOpen: "Menu",
  menuClose: "Close",

  contactHeading: 'Contact us',
  contactCEO: 'CEO',
  contactChairman: 'Chairman of the board',

  navAbout: 'About',
  navHistory: 'Our history',
  navFreshOcean: 'Fresh sea',
  navSeeSalmon: 'SeeSalmon',
  navContactUs: 'Contact us',

  footerMapLink: 'View on Google Maps'
}

export default texts;
import nb from './nb';
import en from './en';

import t from 'counterpart';

t.registerTranslations('nb', nb);
t.registerTranslations('en', en);

t.setFallbackLocale('nb');
t.setLocale('nb');

export default t;


import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import classNames from "classnames"
import scrollTo from 'gatsby-plugin-smoothscroll';

import t from '../../texts/counterpart';

import Logo from "../Logo/Logo"

import * as styles from "./Header.module.css"

const Header = () => {
  const [navToggled, setNavtoggled] = useState(false)

  const handleNavButtonClick = id => {
    setNavtoggled(false);
    document.body.classList.remove('nav-toggled', !navToggled);
    const el = document.querySelector(id);
    if (el) {
      scrollTo(id)
    }
  }

  const toggleNavMenu = () => {
    document.body.classList.toggle('nav-toggled', !navToggled);
    setNavtoggled(!navToggled)
  }

  useEffect(() => {
    if (!navToggled) {
      document.body.classList.remove('nav-toggled');
    }
  }, [navToggled])

  return (
    <header className={styles.wrapper}>
      <div className={styles.container}>
      <Link
        to="/"
        style={{
          textDecoration: `none`,
        }}
      >
        <Logo className={styles.logo} />
        <span className="sr-only">Refsnes Laks</span>
      </Link>

        <button
          onClick={toggleNavMenu}
          className={styles.menuToggle}
        >
          {navToggled ? t('menuClose') : t('menuOpen')}
        </button>

        <nav
          className={classNames(styles.nav, { [styles.toggled]: navToggled })}

        >
          <ul className={styles.links}>
            <li>
              <button onClick={() => handleNavButtonClick("#om")}>{t('navAbout')}</button>
            </li>
            <li>
              <button onClick={() => handleNavButtonClick("#historie")}>{t('navHistory')}</button>
            </li>
            <li>
              <button onClick={() => handleNavButtonClick("#friskt-hav")}>{t('navFreshOcean')}</button>
            </li>
            <li>
              <button onClick={() => handleNavButtonClick("#see-salmon")}>{t('navSeeSalmon')}</button>
            </li>
            <li>
              <button onClick={() => handleNavButtonClick("#kontakt")}>{t('navContactUs')}</button>
            </li>
          </ul>

          <ul className={styles.languageToggle}>
            <li>
              <Link to="/" activeClassName={styles.activeLanguage}>NO</Link></li> 
            <span>/</span>
            <li>
              <Link to="/en" activeClassName={styles.activeLanguage}>EN</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
